import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedService } from '../service/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  count: number = 0;
  constructor(
    public sharedService: SharedService,
    public spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          this.spinner.hide();
        }
      }),
      catchError((err) => {
        this.spinner.hide();
        if (err.status === 401) {
          const message = {
            title: 'Error',
            message: err.error.error,
            status: 'danger',
          };
          this.sharedService.toastr(message);
          this.route.queryParams.subscribe((params) => {
            if (params.returnUrl) {
              this.router.navigate([params.returnUrl]);
            }

            // else {
            //   this.router.navigate(['auth/login']);
            // }
          });

          localStorage.clear();
        } else if (err.status === 422 && err.error.errors) {
          Object.keys(err.error.errors).forEach((key: any) => {
            err.error.errors[key].forEach((msg: any) => {
              const message = {
                title: 'Error',
                message: msg,
                status: 'danger',
                duration: 7000,
              };
              if (this.count == 0) {
                this.sharedService.toastr(message);
              }
              this.count = this.count + 1;
            });
          });
        } else if (err.status === 403) {
          const error = {
            title: 'Error',
            message: err,
            status: 'danger',
            duration: 7000,
          };
          this.sharedService.toastr(error.message);
        } else if (err.status === 404) {
          const message = {
            title: 'Error',
            message: 'URL is not valid. Please open correct url.',
            status: 'danger',
            duration: 7000,
          };
          this.sharedService.toastr(message);
          this.router.navigate(['/auth/login']);
        } else if (err.status === 405) {
          this.sharedService.toastr(err.error);
        } else if (err.status === 405) {
          this.sharedService.toastr(err.error);
        } else if (err.status === 406) {
          const message = {
            title: 'Error',
            message: err.error.status,
            status: 'danger',
            duration: 7000,
          };
          this.sharedService.toastr(message);
        } else if (err.status === 466) {
          const message = {
            title: 'Error',
            message: 'Something went wrong',
            status: 'danger',
            duration: 7000,
          };
          this.sharedService.toastr(message);
        } else if (err.status === 400) {
          // const message = {
          //     message: err.error,
          //     status: 'danger'
          // };
          this.sharedService.toastr(err.error);
        } else if (err.status == 0) {
          const message = {
            message: err.statusText,
            status: 'danger',
          };

          this.sharedService.toastr(message);
        } else if (err.status == 500) {
          this.sharedService.toastr(err.error);
          // this.router.navigate(['/auth/login']);
        }

        return throwError(err);
      })
    );
  }
}
