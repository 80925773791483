import { ToggleService } from './service/toggle.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
// import { Logger, untilDestroyed } from '@core';
import { I18nService } from '@app/i18n';
import { ElementRef, HostListener, Input } from '@angular/core';
import { SharedService } from './service/shared.service';
// const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  message = '';
  classTest = '';

  @HostListener('click', ['$event'])
  clickInside(event: any) {
    if (
      event.target.classList.length > 0 &&
      event.target.classList[0] != 'navbar-collapse' &&
      event.target.classList[0] != 'left_side' &&
      event.target.classList[0] != 'sidebar_img' &&
      event.target.classList[0] != 'fa'
    ) {
      this.sharedService.clickOnPage('click inside');
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private toggleService: ToggleService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.toggleService.currentMessage.subscribe((response: any) => {
      this.message = response;
    });

    this.toggleService.currentClass.subscribe((response: any) => {
      this.classTest = response;
    });
    // Setup logger
    // if (environment.production) {
    //   Logger.enableProductionMode();
    // }

    // log.debug('init');

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data

    // merge(this.translateService.onLangChange, onNavigationEnd)
    //   .pipe(
    //     map(() => {
    //       let route = this.activatedRoute;
    //       while (route.firstChild) {
    //         route = route.firstChild;
    //       }
    //       return route;
    //     }),
    //     filter((route) => route.outlet === 'primary'),
    //     switchMap((route) => route.data),
    //     untilDestroyed(this)
    //   )
    //   .subscribe((event) => {
    //     const title = event.title;
    //     if (title) {
    //       this.titleService.setTitle(this.translateService.instant(title));
    //     }
    //   });
    // end
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
