import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '@app/service/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.scss'],
})
export class TermAndConditionComponent implements OnInit {
  body: any = '';
  constructor(private router: Router, private authenticationService: AuthenticationService) {
    const url = this.router.url;
    //this.authenticationService.activDeactiveSideMenu(url);
  }
  ngOnInit(): void {
    this.getStaticPages();
  }

  // close() {
  //   this.modalService.dismissAll();
  // }

  getStaticPages() {
    const slug = 'terms';
    this.authenticationService.getStaticPages(slug).subscribe((response: any) => {
      if (response) {
        this.body = response.data.body;
      }
    });
  }
}
