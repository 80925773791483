import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SharedService } from '@app/service/shared.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  oldPasswordTextType: boolean;
  newPasswordTextType: boolean;
  confirmPasswordTextType: boolean;
  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private location: Location,
    private sharedService: SharedService
  ) {
    const route = this.router.url;
    this.authenticationService.activDeactiveSideMenu(route);
  }

  ngOnInit(): void {
    this.sharedService.changePage('');
    this.changePasswordForm = this._formBuilder.group({
      old_password: ['', Validators.required],
      new_password: [
        '',
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#^_+|/?&])[A-Za-zd$@$!%*#^_+|/?&].{7,}'),
        ],
      ],
      confirm_password: ['', [Validators.required, confirmPasswordValidator]],
    });
  }
  get f() {
    return this.changePasswordForm.controls;
  }

  toggleOldPassword() {
    this.oldPasswordTextType = !this.oldPasswordTextType;
  }

  toggleNewPassword() {
    this.newPasswordTextType = !this.newPasswordTextType;
  }

  toggleConfirmPassword() {
    this.confirmPasswordTextType = !this.confirmPasswordTextType;
  }

  goToBack() {
    this.location.back();
  }

  submit() {
    if (this.changePasswordForm.value.old_password === '') {
      this.toastr.error('Oops... Current password is required');
      return false;
    }

    if (this.changePasswordForm.value.new_password === '') {
      this.toastr.error('Oops... New password is required');
      return false;
    }

    if (this.changePasswordForm.controls.new_password.status === 'INVALID') {
      this.toastr.error(
        `Oops... New password must contain an uppercase letter, a lowercase letter, a special character, a number and must be 8 characters long`
      );
      return false;
    }

    if (this.changePasswordForm.value.confirm_password === '') {
      this.toastr.error('Oops... Confirm password is required');
      return false;
    }

    if (this.changePasswordForm.value.confirm_password !== this.changePasswordForm.value.new_password) {
      this.toastr.error('Oops... Confirm password must match the new password');
      return false;
    }

    if (this.changePasswordForm.value.new_password !== this.changePasswordForm.value.confirm_password) {
      this.toastr.error('new password must match with Confirm password');
      return false;
    }

    const send_data = {
      old_password: this.changePasswordForm.value.old_password,
      new_password: this.changePasswordForm.value.new_password,
    };

    this.authenticationService.changePassword(send_data).subscribe((data: any) => {
      if (data) {
        this.toastr.success(data.message);
        // this.changePasswordForm.reset();
        this.router.navigate(['/auth/login']);
      }
    });
  }
}
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('new_password');
  const passwordConfirm = control.parent.get('confirm_password');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
