import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './shared.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class CandidateService {
  constructor(public http: HttpClient, public spinner: NgxSpinnerService) {}
  registerStepOne(data: any) {
    const headers = SharedService.getJsonHeader();
    return this.http.post(environment.APIEndpoint + '/candidate/register', data, { headers });
  }

  registerStepTwo(candidate_id: any, data: any) {
    const headers = SharedService.getJsonHeader();
    return this.http.post(environment.APIEndpoint + '/auth/candidate/' + candidate_id, data, { headers });
  }

  registerStepThree(candidate_id: any, data: any) {
    const headers = SharedService.getJsonHeader();
    return this.http.post(environment.APIEndpoint + '/auth/candidate/location/' + candidate_id, data, { headers });
  }

  getAllProvince() {
    const headers = SharedService.getJsonHeader();
    return this.http.get(environment.APIEndpoint + '/state?per_page=50', { headers });
  }

  getCities(Province_id: any) {
    const headers = SharedService.getJsonHeader();
    return this.http.get(environment.APIEndpoint + '/locations?state_id=' + Province_id, { headers });
  }
  getAllPositions() {
    const headers = SharedService.getJsonHeader();
    return this.http.get(environment.APIEndpoint + '/positions', { headers });
  }

  uploadImage(data: FormData): Observable<any> {
    let url = environment.APIEndpoint + '/image-upload';
    return this.http.post(url, data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadVideo(data: FormData): Observable<any> {
    let url = environment.APIEndpoint + '/video-upload';
    return this.http.post(url, data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadFile(data: FormData): Observable<any> {
    let url = environment.APIEndpoint + '/file-upload';
    return this.http.post(url, data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCandidateDetail() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/candidate', { headers });
  }

  getCandidateStripeBalance() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/stripe_balance', { headers });
  }

  updateProfile(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/candidate', data, { headers });
  }

  updateLocation(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/candidate/location', data, { headers });
  }

  getRecommendedJobs() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/candidate/recommended_jobs', { headers });
  }

  getJobDetail(id: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/job/' + id, { headers });
  }

  applyJob(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/job/application', data, { headers });
  }

  getCandidateJobs(query: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/candidate/job' + query, { headers });
  }

  cancelJob(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/job/application/cancel', data, { headers });
  }

  getPastShifts(query: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/candidate/job/past' + query, { headers });
  }

  getUpcomingShift(query: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/candidate/job/upcoming' + query, { headers });
  }
  candidateInvoice(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/candidate/invoice', data, { headers });
  }
  candidateCalender(query: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/candidate/calendar' + query, { headers });
  }
}
