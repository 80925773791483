import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { Time24to12Format } from 'src/assets/pipes/time24to12.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EscapeHtmlPipe } from 'src/assets/pipes/keep-html.pipe';
import { TimeMaskDirective } from '@app/time-mask.directive';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

@NgModule({
  declarations: [Time24to12Format, EscapeHtmlPipe, TimeMaskDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [Time24to12Format, EscapeHtmlPipe, TimeMaskDirective, CalendarModule],
})
export class SharedModule {}
