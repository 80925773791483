import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { SharedService } from '@app/service/shared.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
  currentUser: any;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private sharedService: SharedService
  ) {
    const url = this.router.url;
    this.authenticationService.activDeactiveSideMenu(url);
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.sharedService.changePage('SETTINGS');
  }
}
