import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SelectAccountTypeComponent } from './select-account-type/select-account-type.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerificationComponent } from './verification/verification.component';
import { PasswordSucessfullComponent } from './password-sucessfull/password-sucessfull.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SettingComponent } from './setting/setting.component';
import { SharedModule } from '@app/_helper/shared.module';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { ManagePayoutComponent } from './manage-payout/manage-payout.component';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule,
    SharedModule,
  ],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    SelectAccountTypeComponent,
    ResetPasswordComponent,
    VerificationComponent,
    PasswordSucessfullComponent,
    ChangePasswordComponent,
    SettingComponent,
    LoggedOutComponent,
    ManagePayoutComponent,
    TermAndConditionComponent,
  ],
  exports: [
    // ForgotPasswordComponent,
    // SelectAccountTypeComponent,
    // ResetPasswordComponent,
    // VerificationComponent,
    // PasswordSucessfullComponent,
  ],
})
export class AuthModule {}
