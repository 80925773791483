import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { SharedService } from '@app/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { OfficeService } from '@app/service/office.service';
import { CandidateService } from '@app/service/candidate.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm: FormGroup;
  isLoading = false;
  response: any;
  fieldTextType: boolean;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private officeService: OfficeService,
    private candidateService: CandidateService,
    private route: ActivatedRoute
  ) {
    // const route = this.router.url;
    const url = window.location.pathname;
    this.authenticationService.activDeactiveSideMenu(url);
  }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
          ),
        ]),
      ],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      remember: false,
    });
    localStorage.clear();
  }
  get f() {
    return this.loginForm.controls;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  ngOnDestroy() {}

  login() {
    if (this.loginForm.invalid) {
      this.toastr.success('Oops... Please enter a valid email or password ');
      return;
    }
    const send_data = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
    };
    this.authenticationService.login(send_data).then((data) => {
      this.response = data;
      if (this.response.access_token != null) {
        SharedService.set(SharedService.LOGIN, 'true');
        SharedService.set(SharedService.REMEMBER, this.loginForm.value.remember);
        if (this.loginForm.value.remember === true) {
          localStorage.setItem('rem_email', this.loginForm.value.username);
          localStorage.setItem('rem_pass', this.loginForm.value.password);
        } else {
          localStorage.removeItem('rem_email');
          localStorage.removeItem('rem_pass');
        }
        if (this.response.role === '2') {
          this.getOfficeDetail();
        } else if (this.response.role === '3') {
          this.getCandidateDetail();
        }

        this.toastr.success('Awesome... Login successful');
      }
    });
  }

  getOfficeDetail() {
    this.officeService.getOfficeDetail().subscribe((response: any) => {
      if (response) {
        localStorage.setItem('office_detail_status', response.data.status);

        this.route.queryParams.subscribe((params) => {
          if (params.returnUrl) {
            window.location.href = params.returnUrl;
          } else {
            this.getOfficeJobs();
          }
        });
      }
    });
  }

  getCandidateDetail() {
    this.candidateService.getCandidateDetail().subscribe((response: any) => {
      if (response) {
        localStorage.setItem('candidate_id_job_apply', response.data.id);
        if (response.data.status === '2') {
          localStorage.setItem('candidate_detail_status', response.data.status);
          this.route.queryParams.subscribe((params) => {
            if (params.returnUrl) {
              window.location.href = params.returnUrl;
            } else {
              this.router.navigate(['/candidate/practice']);
            }
          });
        } else {
          localStorage.setItem('candidate_detail_status', response.data.status);
          this.route.queryParams.subscribe((params) => {
            if (params.returnUrl) {
              window.location.href = params.returnUrl;
            } else {
              this.router.navigate(['/candidate/home']);
            }
          });
        }
      }
    });
  }

  getOfficeJobs() {
    const query = '';
    this.officeService.getOfficeJobs(query).subscribe((response: any) => {
      if (response) {
        if (response.data.length > 0) {
          this.router.navigate(['/office/practice']);
        } else {
          this.router.navigate(['/office/home']);
        }
      }
    });
  }
}
