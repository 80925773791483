import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-sucessfull',
  templateUrl: './password-sucessfull.component.html',
  styleUrls: ['./password-sucessfull.component.scss'],
})
export class PasswordSucessfullComponent implements OnInit {
  forgot_email: any;
  otp: any;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.forgot_email = localStorage.getItem('forgot_email');
    this.otp = localStorage.getItem('otp_pin');
    if (this.forgot_email === null || this.otp === null) {
      this.router.navigate(['/auth/login']);
      return;
    }
  }
}
