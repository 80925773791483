import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss'],
})
export class LoggedOutComponent implements OnInit {
  constructor(private router: Router, private authenticationService: AuthenticationService) {
    const url = this.router.url;
    this.authenticationService.activDeactiveSideMenu(url);
  }

  ngOnInit(): void {
    localStorage.clear();
  }

  logout() {
    this.router.navigate(['/auth/login'], { replaceUrl: true });
  }
}
