import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-select-account-type',
  templateUrl: './select-account-type.component.html',
  styleUrls: ['./select-account-type.component.scss'],
})
export class SelectAccountTypeComponent implements OnInit {
  account_type: any = null;
  Form: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    const url = this.router.url;
    this.authenticationService.activDeactiveSideMenu(url);
  }
  ngOnInit(): void {
    this.Form = this.fb.group({
      account_type: [''],
    });
  }
  get f() {
    return this.Form.controls;
  }
  submit() {
    if (this.Form.value.account_type == 'office') {
      this.router.navigate(['/office/create-account']);
    } else if (this.Form.value.account_type == 'professional') {
      this.router.navigate(['/candidate/create-account']);
    } else {
      this.toastr.error("Oops... Please select if you're a Dental Office or a Dental Professional");
      return false;
    }
  }
}
