import { ToggleService } from '../../service/toggle.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@app/auth/authentication.service';
import { SharedService } from '@app/service/shared.service';
import { OfficeService } from '@app/service/office.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = false;
  isHeader = true;
  currentUser: any;
  headerName: any = '';
  userDetail: any;
  notification_response: any = [];
  notification_count = 0;
  show_notification = 20;
  obj: any = {
    notifications: [],
  };
  unread_count: number = 0;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private toggleService: ToggleService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private officeService: OfficeService
  ) {
    const url = this.router.url;
    this.authenticationService.activDeactiveSideMenu(url);
  }

  ngOnInit() {
    this.sharedService.currentPage.subscribe((response) => {
      this.toggleService.changeMessage('');
      this.show_notification = 20;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (this.currentUser) {
        this.getUserDetail();
        this.getNotifications(this.show_notification);
      }
      if (response === 'update profile') {
        this.headerName = '';
      } else {
        this.headerName = response;
      }
    });

    this.sharedService.clickPage.subscribe((response) => {
      if (response == 'click inside') {
        this.menuHidden = false;
        this.toggleService.changeMessage('');
      }
    });
  }

  toggleClass() {
    this.menuHidden = !this.menuHidden;
    if (this.menuHidden === true) {
      this.toggleService.changeMessage('whatsapp');
    } else {
      this.toggleService.changeMessage('');
    }
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  logout() {
    this.officeService.userLogout().subscribe((data: any) => {
      ///this.sharedService.toastr(data);
      this.toastr.success(data.message);
    });
    this.router.navigate(['/auth/login'], { replaceUrl: true });
  }

  profile() {
    if (this.currentUser.role === '2') {
      this.router.navigate(['office/update-profile']);
    } else if (this.currentUser.role === '3') {
      this.router.navigate(['candidate/update-profile']);
    }
  }

  home() {
    if (this.currentUser.role === '2') {
      this.router.navigate(['office/practice']);
    } else if (this.currentUser.role === '3') {
      this.router.navigate(['candidate/practice']);
    }
  }
  getUserDetail() {
    this.sharedService.getUserDetail().subscribe((response: any) => {
      if (response) {
        this.userDetail = response.data;
      }
    });
  }

  getNotifications(show_notification: any) {
    this.sharedService.getNotifications(show_notification).subscribe((response: any) => {
      if (response) {
        this.notification_response = response.data;
        this.obj.notifications = [];
        response.data.forEach((element: any) => {
          if (element.read_at == null) {
            this.obj.notifications.push(element.id);
          }
        });
        this.notification_count = response.meta.pagination.total;
        this.unread_count = response.meta.unreadCount;
        this.show_notification = this.notification_count;
      }
    });
  }

  getNumberWithOrdinal(n: any) {
    var s = ['th', 'st', 'nd', 'rd'],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  postUnreadNotifications() {
    this.sharedService.postUnreadNotifications(this.obj).subscribe((data: any) => {
      if (data) {
        this.unReadCount(this.show_notification);
      }
    });
  }

  unReadCount(show_notification: any) {
    this.sharedService.getNotifications(show_notification).subscribe((response: any) => {
      if (response) {
        this.unread_count = response.meta.unreadCount;
      }
    });
  }
}
