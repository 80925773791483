import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/authentication.service';
import { Router } from '@angular/router';
import { CandidateService } from '@app/service/candidate.service';
import { OfficeService } from '@app/service/office.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SharedService } from '@app/service/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isSidebar = true;
  currentUser: any;
  selectedurl: any = '';
  office_detail_status: any;
  candidate_detail_status: any;
  showPractice: boolean = false;
  showHome: boolean = false;
  anyShiftUpdate: any;
  constructor(
    private router: Router,
    private candidateService: CandidateService,
    private officeService: OfficeService,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService
  ) {
    this.sharedService.currentPage.subscribe((response) => {
      const url = this.router.url;
      this.selectedurl = window.location.pathname;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.office_detail_status = localStorage.getItem('office_detail_status');
      if (this.currentUser !== null && this.currentUser.role === '2') {
        this.getOfficeJobs();
        this.getShiftUpdates();
      }

      if (this.currentUser !== null && this.currentUser.role === '3') {
        this.candidate_detail_status = localStorage.getItem('candidate_detail_status');
        this.getCandidateDetail();
      }
      this.authenticationService.activDeactiveSideMenu(url);
    });
  }

  ngOnInit(): void {}

  getCandidateDetail() {
    this.candidateService.getCandidateDetail().subscribe((response: any) => {
      if (response) {
        localStorage.setItem('candidate_id_job_apply', response.data.id);
        if (response.data.status === '2') {
          localStorage.setItem('candidate_detail_status', response.data.status);
          if (this.selectedurl === '/candidate/home') {
            this.router.navigate(['candidate/practice']);
          }
        } else {
          localStorage.setItem('candidate_detail_status', response.data.status);
          if (this.selectedurl === '/candidate/practice') {
            this.router.navigate(['candidate/home']);
          }
        }
      }
    });
  }

  getOfficeJobs() {
    const query = '';
    this.officeService.getOfficeJobs(query).subscribe((response: any) => {
      if (response) {
        if (response.data.length > 0) {
          this.showPractice = true;
          this.showHome = false;
          this.selectedurl = '/office/practice';
        } else {
          this.showHome = true;
          this.showPractice = false;

          if (this.selectedurl === '/office/practice') {
            this.selectedurl = '/office/home';
            this.router.navigate(['/office/home']);
          }
        }
      }
    });
  }

  getShiftUpdates() {
    this.officeService.getOfficeNewNotification().subscribe((data: any) => {
      this.anyShiftUpdate = data?.result;
    });
  }
}
