import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleService {
  private messageSource = new BehaviorSubject('');
  private classSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();
  currentClass = this.classSource.asObservable();

  constructor() {}
  changeMessage(message: string) {
    this.messageSource.next(message);
  }
  changeClass(message: string) {
    this.classSource.next(message);
  }
}
