import { HeaderComponent } from './shell/header/header.component';
import { SidebarComponent } from './shell/sidebar/sidebar.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { CoreModule } from '@core';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OfficeGuard } from './_guards/office.guard';
import { CandidateGuard } from './_guards/candidate.guard';
import { ErrorInterceptor } from './_helper/error.interceptor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { SharedModule } from './_helper/shared.module';
import { AuthModule } from './auth/auth.module';
import { TimeAgoPipe } from 'time-ago-pipe';
import { NgScrollbarModule } from 'ngx-scrollbar';
@NgModule({
  imports: [
    BrowserModule,
    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    ShellModule,
    AuthModule,
    NgScrollbarModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true,
      preventDuplicates: true,
      positionClass: 'toast-top-right',
    }),
    BrowserAnimationsModule,
    SharedModule,
    TimepickerModule.forRoot(),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    TimeAgoPipe,
    // EscapeHtmlPipe,
  ],
  providers: [OfficeGuard, CandidateGuard, { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [SidebarComponent, HeaderComponent],
})
export class AppModule {}
