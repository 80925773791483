import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'time24to12Transform' })
export class Time24to12Format implements PipeTransform {
  transform(time: any): any {
    if (time != null) {
      let hour = time.split(':')[0];
      let min = time.split(':')[1];
      let part = hour >= 12 ? 'pm' : 'am';
      min = (min + '').length == 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour + '').length == 1 ? `0${hour}` : hour.toString();
      if (hour.substring(0, 1) == 0) {
        hour = hour.substring(1, 2);
      }
      return `${hour}:${min} ${part}`;
    } else {
      return '';
    }
  }
}
