import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('new_password');
  const passwordConfirm = control.parent.get('confirm_password');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  forgot_email: any;
  otp: any;
  newPasswordTextType: boolean;
  confirmPasswordTextType: boolean;
  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private location: Location,
    private authenticationService: AuthenticationService
  ) {
    const route = this.router.url;
    this.authenticationService.activDeactiveSideMenu(route);
  }

  ngOnInit(): void {
    this.forgot_email = localStorage.getItem('forgot_email');
    this.otp = localStorage.getItem('otp_pin');
    if (this.forgot_email === null || this.otp === null) {
      this.router.navigate(['/auth/login']);
      return;
    }
    this.resetForm = this._formBuilder.group({
      new_password: [
        '',
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#^_+|/?&])[A-Za-zd$@$!%*#^_+|/?&].{7,}'),
        ],
      ],
      confirm_password: ['', [Validators.required, confirmPasswordValidator]],
    });
  }
  get f() {
    return this.resetForm.controls;
  }

  toggleNewPassword() {
    this.newPasswordTextType = !this.newPasswordTextType;
  }

  toggleConfirmPassword() {
    this.confirmPasswordTextType = !this.confirmPasswordTextType;
  }
  submit() {
    if (this.resetForm.value.new_password === '') {
      this.toastr.error('Oops... New password is required');
      return false;
    }

    if (this.resetForm.controls.new_password.status === 'INVALID') {
      this.toastr.error(
        `Oops... New password must contain an uppercase letter, a lowercase letter, a special character, a number and must be 8 characters long`
      );
      return false;
    }

    if (this.resetForm.value.confirm_password === '') {
      this.toastr.error('Oops... Confirm password is required');
      return false;
    }

    if (this.resetForm.value.confirm_password !== this.resetForm.value.new_password) {
      this.toastr.error('Oops... Confirm password must match the new password');
      return false;
    }

    if (this.resetForm.value.new_password !== this.resetForm.value.confirm_password) {
      this.toastr.error('Oops... your password does not match');
      return false;
    }

    const send_data = {
      otp_pin: this.otp,
      password: this.resetForm.value.new_password,
      email: this.forgot_email,
    };

    this.authenticationService.resetPassword(send_data).subscribe((data: any) => {
      if (data) {
        this.toastr.success(data.status);
        this.router.navigate(['/password']);
      }
    });
  }

  goToBack() {
    this.location.back();
  }
}
