import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToggleService } from '@app/service/toggle.service';
import { environment } from '../../environments/environment';
import { SharedService } from '@app/service/shared.service';
import { ToastrService } from 'ngx-toastr';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isSidebar = true;
  isHeader = true;
  BaseUrl: any;
  loginUrl: any;
  forgotpasswordUrl: any;
  constructor(private toggleService: ToggleService, public http: HttpClient, private toastr: ToastrService) {
    this.BaseUrl = environment.APIEndpoint;
    this.loginUrl = this.BaseUrl + '/auth/login';
    this.forgotpasswordUrl = this.BaseUrl + '/auth/recovery';
  }

  login(data: any) {
    //const headers = SharedService.getJsonHeader();//
    const headers = SharedService.getloginHeader();
    return new Promise((resolve, reject) => {
      this.http.post(this.loginUrl, data, { headers }).subscribe(
        (res) => {
          resolve(res);

          localStorage.setItem('currentUser', JSON.stringify(res));
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  forgotpassword(email_data: any) {
    const data = {
      email: email_data,
    };
    const headers = SharedService.getIsProductionHeader();
    return new Promise((resolve, reject) => {
      this.http.post(this.forgotpasswordUrl, data, headers).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          if (err.error.errors.email[0]) {
            this.toastr.error(err.error.errors.email[0]);
          }
          reject(err);
        }
      );
    });
  }

  verifyPin(data: any) {
    const headers = SharedService.getIsProductionHeader();
    return this.http.post(environment.APIEndpoint + '/auth/otp-verify', data, headers);
  }

  resetPassword(data: any) {
    const headers = SharedService.getIsProductionHeader();
    return this.http.post(environment.APIEndpoint + '/auth/reset', data, headers);
  }

  changePassword(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/change_password', data, { headers });
  }

  getStaticPages(slug: any) {
    return this.http.get(environment.APIEndpoint + '/pages/' + slug);
  }

  activDeactiveSideMenu(param: string) {
    if (param === '/' || param === '/login' || param === '/auth/login') {
      this.isSidebar = false;
      this.isHeader = false;
      this.toggleService.changeClass('hideHeader login_bg');
    } else if (
      param === '/forgot-password' ||
      param === '/resetpassword' ||
      param === '/verification' ||
      param === '/select-account' ||
      param === '/office/create-account' ||
      param === '/office/manage-profile' ||
      param === '/office/payment-details' ||
      param === '/candidate/create-account' ||
      param === '/candidate/manage-profile' ||
      param === '/candidate/payment-details' ||
      param === '/candidate/manage-location'
    ) {
      this.isSidebar = false;
      this.isHeader = false;
      this.toggleService.changeClass('hideHeader');
    } else if (param === '/candidate/job-posting') {
      this.isSidebar = true;
      this.isHeader = true;
      this.toggleService.changeClass('box-header');
    } else if (
      param === '/office/home' ||
      param === '/candidate/home' ||
      param === '/candidate/practice' ||
      param === '/office/practice' ||
      param === '/candidate/my-shifts' ||
      param === '/office/invoices-listing' ||
      param === 'Invoice-view' ||
      param === '/office/permanent' ||
      param === '/office/post-shift' ||
      param === '/office/privacy-policy' ||
      param === '/office/calendar' ||
      param === '/candidate/calendar' ||
      param === '/candidate/temp-network' ||
      param === '/setting'
    ) {
      this.isSidebar = true;
      this.isHeader = true;
      this.toggleService.changeClass('box-header-gray');
    } else if (param === '/loggedout') {
      this.isSidebar = false;
      this.isHeader = false;
      this.toggleService.changeClass('hideHeader');
    } else {
      this.isSidebar = true;
      this.isHeader = true;
      this.toggleService.changeClass('bg-white');
    }
  }
}
