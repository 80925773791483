import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CandidateGuard } from './_guards/candidate.guard';
import { OfficeGuard } from './_guards/office.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },

  {
    path: 'candidate',
    loadChildren: () =>
      import('./dental-professtional/dental-professtional.module').then((m) => m.DentalProfesstionalModule),
  },
  {
    path: 'office',
    loadChildren: () => import('./dental-office/dental-office.module').then((m) => m.DentalOfficeModule),
  },
  { path: '', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule) },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: 'auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
