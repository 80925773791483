import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private pageSource = new BehaviorSubject('');
  currentPage = this.pageSource.asObservable();

  private clickBody = new BehaviorSubject('');
  clickPage = this.clickBody.asObservable();
  static LOGIN: string = 'login';
  static TOKEN: string = 'token';
  static REMEMBER: string = 'remember';
  static get(key: string) {
    return localStorage.getItem(key);
  }

  static clearStorage() {
    localStorage.clear();
  }

  static set(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  static getJsonHeader() {
    return new HttpHeaders().set('Content-Type', 'application/json');
  }

  static getloginHeader() {
    return new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Methods', 'GET, POST, DELETE, PUT,PATCH');
  }
  static getIsProductionHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        'is-production': 'false',
      }),
    };
    return httpOptions;
  }

  static getHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'is-production': 'false',
      }),
    };
    return httpOptions;
  }

  static getPutHeader() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser != null) {
      return new HttpHeaders().set('Authorization', `Bearer ${currentUser.access_token}`);
    } else {
      window.location.href = '/auth/login';
    }
  }
  constructor(private toasterService: ToastrService, public http: HttpClient) {}
  public toastr(data: any) {
    this.toasterService.error(data.message);
  }
  changePage(message: string) {
    this.pageSource.next(message);
  }

  clickOnPage(message: string) {
    this.clickBody.next(message);
  }

  getUserDetail() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/user-image', { headers });
  }

  getNotifications(per_page: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/notifications?per_page=' + per_page, { headers });
  }

  postUnreadNotifications(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/notifications', data, { headers });
  }

  getPayoutUrl() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/stripe_payout_url', { headers });
  }

  getstripeResponse() {
    const headers = SharedService.getloginHeader();
    return this.http.get(environment.APIEndpoint + '/stripe_', { headers });
  }
}
