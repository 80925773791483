import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SelectAccountTypeComponent } from './select-account-type/select-account-type.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerificationComponent } from './verification/verification.component';
import { PasswordSucessfullComponent } from './password-sucessfull/password-sucessfull.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SettingComponent } from './setting/setting.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { AuthenticationGuard } from './authentication.guard';
import { ManagePayoutComponent } from './manage-payout/manage-payout.component';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';

const routes: Routes = [
  { path: 'auth/login', component: LoginComponent, data: { title: extract('Login') } },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'term_and_conditions', component: TermAndConditionComponent },
  { path: 'select-account', component: SelectAccountTypeComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'verification', component: VerificationComponent },
  { path: 'password', component: PasswordSucessfullComponent },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthenticationGuard] },
  { path: 'setting', component: SettingComponent, canActivate: [AuthenticationGuard] },
  { path: 'manage-payout', component: ManagePayoutComponent, canActivate: [AuthenticationGuard] },
  { path: 'loggedout', component: LoggedOutComponent, canActivate: [AuthenticationGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
