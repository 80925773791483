import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private location: Location
  ) {
    const route = this.router.url;
    this.authenticationService.activDeactiveSideMenu(route);
  }
  ngOnInit(): void {
    this.forgotForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
          ),
        ]),
      ],
    });
  }
  get f() {
    return this.forgotForm.controls;
  }
  submit() {
    if (this.forgotForm.value.email === '') {
      this.toastr.error('Oops... Email is required');
      return false;
    }
    if (this.forgotForm.invalid) {
      this.toastr.error('Oops... Please enter a valid email');
      return false;
    }
    localStorage.setItem('forgot_email', this.forgotForm.value.email);
    this.authenticationService.forgotpassword(this.forgotForm.value.email).then((data) => {
      if (data) {
        this.router.navigate(['/verification']);
        this.toastr.info('Please check your email and enter the 6 digit One Time Password');
      }
    });
  }

  goToBack() {
    this.location.back();
  }
}
