import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SharedService } from './shared.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfficeService {
  constructor(public http: HttpClient) {}
  registerStepOne(data: any) {
    const headers = SharedService.getJsonHeader();
    return this.http.post(environment.APIEndpoint + '/office/register', data, { headers });
  }

  registerStepTwo(office_id: any, data: any) {
    const headers = SharedService.getJsonHeader();
    return this.http.post(environment.APIEndpoint + '/auth/office/' + office_id, data, { headers });
  }

  createStripeCustomer(office_id: any, data: any) {
    return this.http.post(environment.APIEndpoint + '/auth/office/payment/' + office_id, data);
  }

  uploadFile(data: FormData): Observable<any> {
    let url = environment.APIEndpoint + '/image-upload';
    return this.http.post(url, data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getOfficeDetail() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/office', { headers });
  }

  postShift(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/job_post', data, { headers });
  }

  updateShift(job_id: any, data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/job_post/' + job_id, data, { headers });
  }

  updateProfile(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/office', data, { headers });
  }

  getOfficeJobs(query: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/job' + query, { headers });
  }

  getOfficePayment() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/office/payment', { headers });
  }

  postPaymentDetail(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/office/payment', data, { headers });
  }

  getShiftApplications(query: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/application' + query, { headers });
  }

  getShiftApplicationsDetail(id: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/job/application/' + id, { headers });
  }

  postBookApplicant(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/job/book-applicant', data, { headers });
  }

  postCancelApplicant(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/job/cancel-applicant', data, { headers });
  }

  getOfficeJobDetail(id: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/office/job/' + id, { headers });
  }

  removeJob(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/job/remove', data, { headers });
  }

  getAllProvince() {
    const headers = SharedService.getJsonHeader();
    return this.http.get(environment.APIEndpoint + '/state?per_page=50', { headers });
  }

  getCities(Province_id: any) {
    const headers = SharedService.getJsonHeader();
    return this.http.get(environment.APIEndpoint + '/locations?state_id=' + Province_id, { headers });
  }

  getInvoicePending() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/office/invoice/pending', { headers });
  }

  officeConfirmHours(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/office/invoice/confirm-hours', data, { headers });
  }

  officeRatingAndComment(data: any) {
    const headers = SharedService.getPutHeader();
    return this.http.post(environment.APIEndpoint + '/office/rating-comment', data, { headers });
  }

  getInvoices() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/office/invoice', { headers });
  }
  getInvoiceDetail(id: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/office/invoice/' + id, { headers });
  }

  officeCalender(query: any) {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/office/calendar' + query, { headers });
  }

  getOfficeStatus() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/office/check-status', { headers });
  }

  getOfficeNewNotification() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/newnotifications', { headers });
  }

  userLogout() {
    const headers = SharedService.getPutHeader();
    return this.http.get(environment.APIEndpoint + '/auth/logout', { headers });
  }
}
