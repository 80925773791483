import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
  resetCode: any = '';
  forgot_email: any;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private authenticationService: AuthenticationService
  ) {
    const route = this.router.url;
    this.authenticationService.activDeactiveSideMenu(route);
  }

  ngOnInit(): void {
    this.forgot_email = localStorage.getItem('forgot_email');
    if (this.forgot_email == null) {
      this.router.navigate(['/auth/login']);
      return;
    }
  }
  onOtpChange(event: any) {
    this.resetCode = event;
  }

  submit() {
    if (this.resetCode.length < 6 || this.resetCode === '') {
      const message = {
        message: 'Oops... One Time Password is required',
        status: 'danger',
        duration: 7000,
      };
      this.toastr.error(message.message);
      return;
    }
    localStorage.setItem('otp_pin', this.resetCode);

    const send_data = {
      otp_pin: this.resetCode,
      email: this.forgot_email,
    };

    this.authenticationService.verifyPin(send_data).subscribe((data) => {
      if (data) {
        this.router.navigate(['/resetpassword']);
        this.toastr.success('Awesome... One Time Password verified', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    });
  }

  resendPassword() {
    let forgot_email = localStorage.getItem('forgot_email');
    this.authenticationService.forgotpassword(forgot_email).then((data) => {
      if (data) {
        this.toastr.success('Please check your email and enter the 6 digit One Time Password', '', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      }
    });
  }

  goToBack() {
    this.location.back();
  }
}
