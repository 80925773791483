import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from '../authentication.service';
import { SharedService } from '@app/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { CandidateService } from '@app/service/candidate.service';
import { timepickerReducer } from 'ngx-bootstrap/timepicker/reducer/timepicker.reducer';

@Component({
  selector: 'app-manage-payout',
  templateUrl: './manage-payout.component.html',
  styleUrls: ['./manage-payout.component.scss'],
})
export class ManagePayoutComponent implements OnInit {
  currentUser: any;
  stripePayoutStatus: any;
  stripeBalanceData: any;
  stripeStatus: boolean = true;
  balance: any;
  phone: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private sharedService: SharedService,
    private candidateService: CandidateService
  ) {
    const url = this.router.url;
    this.authenticationService.activDeactiveSideMenu(url);
  }

  ngOnInit(): void {
    this.stripeStatus = true;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getProfile();
  }

  setup_payout() {
    this.sharedService.getPayoutUrl().subscribe((data: any) => {
      if (data.data.status == true) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // this.passEntry.emit(data);
        // this.modalService.dismissAll();
        // data.data.stripe_payout_url
        // window.open(data.data.stripe_payout_url+'?response_type=code&redirect_uri=https://70285c5b3445.ngrok.io&client_id='+currentUser.user_id+'&scope=read_write&stripe_user["email"]='+currentUser.email+'&stripe_user["first_name"]='+currentUser.first_name+'&stripe_user["last_name"]='+currentUser.last_name+'&stripe_user["business_type"]=individual');
        window.open(
          data.data.stripe_payout_url +
            '?email=' +
            currentUser.email +
            '&phone_number=' +
            this.phone +
            '&Bbusiness_type=individual&Bcountry=US&first_name=' +
            currentUser.first_name +
            '&last_name=' +
            currentUser.last_name,
          '_self'
        );
      } else {
        this.toastr.error(data.data.message);
      }
    });
  }

  getProfile() {
    this.candidateService.getCandidateDetail().subscribe((response: any) => {
      this.phone = response.data.phone;
      this.stripePayoutStatus = response.data.stripe_payee_status;
      if (response.data.stripe_payee_status == 'Active' && response.data.stripe_payee_id != '') {
        this.getCandidateStripeBalance();
      }
      if (response.data.stripe_payee_status == 'inactive' && response.data.stripe_payee_id != '') {
        this.stripeStatus = false;
      }
      if (
        response.data.stripe_payee_status == 'inactive' &&
        (response.data.stripe_payee_id == '' || response.data.stripe_payee_id == null)
      ) {
        this.stripeStatus = true;
      }
    });
  }

  goToBack() {
    this.location.back();
  }

  getCandidateStripeBalance() {
    this.candidateService.getCandidateStripeBalance().subscribe((response: any) => {
      this.balance = response.data.amount / 100;
      this.stripeStatus = response.data.status;
    });
  }
}
